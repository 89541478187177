import React, { useState } from "react";
import AsyncSelect from "react-select/async";

function URLify(string) {
  return string.trim().replace(/\s/g, "%20");
}

const getCourthouse = (values, props, place_name) => {
  // props.onTitleChange()
  // console.log(values);
  var lat = values[1];
  var long = values[0];
  var coords = JSON.stringify({
    coords: values,
  });

  //Removes state and breaks string into fields
  var address = place_name.replace("California ", "").split(", ");

  var postOptions = {
    method: "POST",
    body: coords,
  };

  var results = fetch("/.netlify/functions/"+process.env.GATSBY_LOCATOR_FUNCTION, postOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log("Determining if in LA County");
      if (Object.keys(result).length != 0){
        props.onTitleChange(result, address, place_name, lat, long);
      } else {
        props.onSearchAddress(3)
        // console.log("here")
        console.log(result)
      }
      
      // console.log(result)
      // return result
    });

  return results;
};

// const onAddressSearch=(props)=> {
//   props.onSearchAddress(2)
// }

const customStyles = {
  option: (styles) => ({ ...styles, backgroundColor: "none" }),
};

export default function Locator(props) {
  const mapboxtoken = `.json?access_token=${process.env.GATSBY_MAPBOX_API_ACCESS_TOKEN}`;
  const geocodingParam =
    "&country=us&types=address&proximity=-118.24740624309874%2C34.043141274871246&bbox=-118.9446306258178936%2C33.7036694037813476%20%2C%20-117.6463738759974689%2C34.8233064276897792";
  const [value, setValue] = useState("");
  const [confirmAddress, setconfirmAddress] = useState("");
  const [coord, setCoord] = useState({ longitude: null, latitude: null });

  return (
    <div className="Locator">
      <AsyncSelect
        // inputValue={value}
        placeholder={props.addressplaceholderform}
        // styles={customStyles}
        loadOptions={(value) => {
          if (value.length < 3) {
            return;
          }
          const urlRequest =
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            URLify(value) +
            mapboxtoken +
            geocodingParam;
          return fetch(urlRequest)
            .then((res) => res.json())
            .then((json) => json.features)
            .catch((err) => {
              // console.log("holi");
              console.log(err);
            });
        }}
        onInputChange={(input) => setValue(input)}
        getOptionLabel={(option) => option.place_name}
        noOptionsMessage={(e) => (e.inputValue ? "No options" : null)}
        onClick={(input) => setValue("Enter Your Address")}
        onChange={({
          place_name = "",
          geometry: { coordinates = ["", ""] },
        }) => {
          props.onSearchAddress(2)
          getCourthouse(coordinates, props, place_name);
          setValue(place_name);
          setconfirmAddress(place_name);
          setCoord({
            longitude: coordinates[0],
            latitude: coordinates[1],
          });
        }}
      />
    </div>
  );
}

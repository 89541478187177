import React from 'react';
import { render } from "storyblok-rich-text-react-renderer"
import { ErrorMessage, Field } from "formik";

const Step_Response = props => {
    const { values, content, back, next, handleSubmit } = props

    return <div>
        <div className="content has-text-centered">
            <img
                className="service-image"
                src={content.image.filename}
                alt={content.image.alt}
            />
            <h1 className="mb-6">{content.title}</h1>
            {render(content.response)}
        </div>
    </div >
}

export default Step_Response;
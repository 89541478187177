import React from 'react';
import { ErrorMessage, Field } from "formik";

const Step02_SelectServices = props => {
    const { values, content, back, next, handleSubmit } = props

    const services = content.services.map((service, i) => {
        console.log(service.display)
        let card = service.display ?
            <div className="control mb-3" key={i}>
                <label className="is-size-6">
                    <Field
                        type="checkbox"
                        name="submitted_requests"
                        value={service.value}
                        className="mr-2"
                    />
                    <span>{service.service}</span>
                </label>
            </div>
            :
            <></>

        return card;
    }
    )

    return <div>
        <progress className="progress" value="15" max="100">
            15%
        </progress>
        <div className="content">
            <p className="is-size-3 label">
                {content.question}
            </p>
            <p className="is-size-5">
                {content.description}
            </p>
            {services}
        </div>
        <ErrorMessage
            className="tag is-warning is-normal has-text-weight-semibold"
            component="div"
            name="submitted_requests"
        />
    </div >
}

export default Step02_SelectServices;
import React from 'react';
import { Field } from "formik";
import { render } from "storyblok-rich-text-react-renderer"
import Locator from "./locator"

const Step01_AddressSearch = props => {
    const { values, next, content } = props
    // console.log(values)
    // console.log(content)

    const handleAddressButton = () => {
        const validateAddress = values.inLAcounty == 0 ? null :
            values.inLAcounty == 2 ? null :
                next
        return validateAddress
    }

    return <div>
        <progress className="progress" value="5" max="100">
            5%
        </progress>
        <p className="is-size-3 label pb-4">{content.question}</p>
        <div className="content">
            {render(content.description)}
        </div>
        <Field
            name="inLAcounty"
            children={({ field, form }) => (
                <>
                    <Locator
                        addressplaceholderform={content.search_text}
                        onSearchAddress={(evt, value) => {
                            // console.log(evt);
                            form.setFieldValue("inLAcounty", evt);
                        }}
                        onTitleChange={(evt, address, place_name, lat, long) => {
                            console.log("In LA County");
                            if (Object.keys(evt).length != 0 && evt.inLAcounty == 1) {
                                form.setFieldValue("geocoded_address", place_name);
                                form.setFieldValue("street_address", address[0]);
                                form.setFieldValue("city", address[1]);
                                form.setFieldValue("zip", address[2]);
                                form.setFieldValue("inLAcounty", evt.inLAcounty);
                                form.setFieldValue("lat", lat);
                                form.setFieldValue("long", long);
                                form.setFieldValue("spa_area", evt.spa);
                                form.setFieldValue("community", evt.community_name.label);
                            }
                        }}
                    />
                    <div className="field mt-3">
                        <label className="label" htmlFor="apt">
                            {content.apt}
                        </label>
                        <Field
                            className="input"
                            component="input"
                            id="apt"
                            name="apt"
                            placeholder="Optional"
                            type="text"
                        />
                    </div>
                    {form.values.inLAcounty == 3 ? (
                        <div className="notification is-warning has-text-weight-semibold mt-3">
                            {content.warning}
                        </div>
                    ) : form.values.inLAcounty == 1 ? (
                        <div className="notification is-primary has-text-weight-semibold mt-3">
                            {content.success}
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="notification is-warning is-light mt-3">
                        <div className="content">
                            {render(content.privacy)}
                        </div>
                    </div>
                </>
            )}
        />
    </div>
}

export default Step01_AddressSearch;
import React from 'react';
import { ErrorMessage, Field } from "formik";
import providerList from "./json/providerList.json"

const Step06_Provider = props => {
    const { values, content, back, next, handleSubmit } = props
    console.log(content)

    return <div>
        <progress className="progress" value="75" max="100">
            75%
        </progress>

        <div className="content">
            <p className="is-size-3 label">
                {content.question}
            </p>
            <div className="control mb-3">
                <label className="is-size-6 mr-4">
                    <Field
                        type="radio"
                        name="referredbyprovider"
                        value="yes"
                        className="mr-2"
                    />
                    <span>{content.yes}</span>
                </label>
                <label className="is-size-6 mr-4">
                    <Field
                        type="radio"
                        name="referredbyprovider"
                        value="no"
                        className="mr-2"
                    />
                    <span>{content.no}</span>
                </label>
                <ErrorMessage
                    className="tag is-warning is-normal has-text-weight-semibold"
                    component="div"
                    name="referredbyprovider"
                />
                <label className="pt-4">
                    {values.referredbyprovider == "yes" ?
                        <div>
                            <Field
                                className="select input"
                                name="outreached_by"
                                component="select"
                                placeholder="Size"
                            >
                                {Object.keys(providerList).map((item, i) => (
                                    <option value={item} key={i}>{providerList[item]}</option>
                                ))}
                            </Field>
                        </div>
                        : ""
                    }
                </label>
                <div className="pt-4">
                    {values.referredbyprovider == "yes" ?
                        <div>
                            <p className="label is-size-6">
                                {content.question_for_referrer}
                            </p>
                            <label>
                                <p className="label" htmlFor="referred_by_name">
                                    {content.name_of_referrer}
                                </p>
                                <Field component="input" name="referred_by_name" className="input" />
                            </label>
                            <label>
                                <p className="label" htmlFor="referred_by_email">
                                    {content.email_of_referrer}
                                </p>
                                <Field component="input" name="referred_by_email" className="input" />
                            </label>
                        </div>
                        : ""
                    }
                </div>
                <label className="pt-4">
                    {values.outreached_by == "Other" && values.referredbyprovider == "yes" ?
                        <>
                            <p className="label">
                                Name of Organization
                            </p>
                            <Field component="input" name="outreached_other" className="input" />
                        </>

                        : null
                    }
                </label>
            </div>
        </div>
    </div >
}

export default Step06_Provider;
import React from 'react';
import { ErrorMessage, Field } from "formik";

const Step03_Household = props => {
    const { values, content, back, next, handleSubmit } = props

    return <div>
        <progress className="progress" value="30" max="100">
            30%
        </progress>

        <div className="content">
            <p className="is-size-3 label">
                {content.question}
            </p>
            <div className="control mb-3">
                <label className="is-size-6 mr-4">
                    <Field
                        type="radio"
                        name="client_an_immigrant"
                        value="yes"
                        className="mr-2"
                    />
                    <span>{content.yes}</span>
                </label>
                <label className="is-size-6 mr-4">
                    <Field
                        type="radio"
                        name="client_an_immigrant"
                        value="no"
                        className="mr-2"
                    />
                    <span>{content.no}</span>
                </label>
                <ErrorMessage
                    className="tag is-warning is-normal has-text-weight-semibold"
                    component="div"
                    name="client_an_immigrant"
                />
            </div>
        </div>
    </div >
}

export default Step03_Household;
import React, { useState } from "react";
import DynamicComponent from "./DynamicComponent"
import formData from "./pages/intake/json/formData"
// import { Debug } from "./pages/intake/debug";
import ValidationData from "./pages/intake/json/formValidation";
import { sbEditable } from "@storyblok/storyblok-editable";
import { Formik, Form } from "formik";
import Step00_Start from "./pages/intake/Step00-Start";
import Step01_AddressSearch from "./pages/intake/Step01-AddressSearch";
import Step02_SelectServices from "./pages/intake/Step02_SelectServices";
import Step03_Household from "./pages/intake/Step03_Household";
import Step04_IncomeCovid from "./pages/intake/Step04_IncomeCovid";
import Step05_ContactInfo from "./pages/intake/Step05_ContactInfo";
import Step06_Provider from "./pages/intake/Step06_Provider";
import Step07_Submit from "./pages/intake/Step07_Submit";
import Step_Response from "./pages/intake/Step_Response";
import { Link } from "gatsby";

const IntakeForm = ({ blok }) => {
    // console.log(blok)

    const validateSchema = ValidationData(blok.body)

    const renderStep = (step, values, errors, touched, handleChange, handleSubmit, next, back, stepChange, signupSuccess, handleBlur, isValidating) => {
        switch (step) {
            case 1:
                return (
                    <Step00_Start
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        next={next}
                        handleBlur={handleBlur}
                        content={blok.body[1]}
                    />);
            case 2:
                return (
                    <Step01_AddressSearch
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        next={next}
                        handleBlur={handleBlur}
                        content={blok.body[2]}
                    />);
            case 3:
                return (
                    <Step02_SelectServices
                        values={values}
                        errors={errors}
                        touched={touched}
                        isValid={isValidating}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        next={next}
                        back={back}
                        handleBlur={handleBlur}
                        content={blok.body[3]}
                    />);
            case 4:
                return (
                    <Step03_Household
                        values={values}
                        errors={errors}
                        touched={touched}
                        isValid={isValidating}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        next={next}
                        back={back}
                        handleBlur={handleBlur}
                        content={blok.body[4]}
                    />);
            case 5:
                return (
                    <Step04_IncomeCovid
                        values={values}
                        errors={errors}
                        touched={touched}
                        isValid={isValidating}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        next={next}
                        back={back}
                        handleBlur={handleBlur}
                        content={blok.body[5]}
                    />);
            case 6:
                return (
                    <Step05_ContactInfo
                        values={values}
                        errors={errors}
                        touched={touched}
                        isValid={isValidating}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        next={next}
                        back={back}
                        handleBlur={handleBlur}
                        content={blok.body[6]}
                    />);
            case 7:
                return (
                    <Step06_Provider
                        values={values}
                        errors={errors}
                        touched={touched}
                        isValid={isValidating}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        next={next}
                        back={back}
                        handleBlur={handleBlur}
                        content={blok.body[7]}
                    />);
            case 8:
                return (
                    <Step07_Submit
                        values={values}
                        errors={errors}
                        touched={touched}
                        isValid={isValidating}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        next={next}
                        back={back}
                        handleBlur={handleBlur}
                        content={blok.body[8]}
                    />);
            case 9:
                return (
                    <Step_Response
                        content={blok.body[9]}
                    />);
        }
    };

    const assignOrg = (values) => {
        var orgbody = JSON.stringify({ values });
        var orgOptions = {
            method: "POST",
            body: orgbody,
        };
        return fetch("/.netlify/functions/assignOrg", orgOptions)
            .then((response) => response.json())
            .then((result) => {
                return result;
            });
    };

    const submitLog = (values) => {
        var logBody = JSON.stringify({
            logs: values,
        });
        var logOptions = {
            method: "POST",
            body: logBody,
        };
        fetch("/.netlify/functions/submitLog", logOptions).then((response) =>
            response.json()
        );
    };

    const submitForm = (values) => {
        var formBody = JSON.stringify(values);
        console.log("Submitting");
        var formOptions = {
            method: "POST",
            body: formBody,
        };
        fetch("/.netlify/functions/submitForm", formOptions).then((response) =>
            response.json()
        );
        // .then(result => console.log(result))
    };

    const emailApplicant = (values) => {
        var email = JSON.stringify({
            email: values.email,
            primary_partner_name: values.primary_partner_name,
            lang: blok.body[0].lang.cached_url
        });
        // console.log(orgbody)
        var emailOptions = {
            method: "POST",
            body: email,
        };
        return fetch("/.netlify/functions/emailApplicant", emailOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Applicant emailed");
            });
    };

    const emailProvider = (values) => {
        var email = JSON.stringify({
            primary_partner_email: values.primary_partner_email,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
            preferred_language: values.preferred_language,
            accommodation_request: values.accommodation_request,
            submitted_requests: values.submitted_requests
        });
        // console.log(orgbody)
        var emailOptions = {
            method: "POST",
            body: email,
        };
        return fetch("/.netlify/functions/emailProvider", emailOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Applicant emailed");
            });
    };


    const MultiStep = (onSubmit) => {
        const [step, setStep] = useState(1);
        const [signupSuccess, setSignupSuccess] = useState(false)
        const formikData = formData

        const next = (values) => {
            console.log("next")
            const documentReceived = values.document
            const noticeType = values.notice
            setStep(s => s + 1)
            window.gtag("event", "formstep", {
                'step': step
            })
        }

        // process to previous step
        const back = (values) => {
            window.scrollTo(0, 0)
            console.log("back")
            // console.log(values)
            setStep(s => s - 1)
        }

        const handleSubmit = async (values, bag) => {
            window.scrollTo(0, 0)
            bag.setTouched({});
            if (step == 8) {
                const getOrgs = await assignOrg(values).then((result) => {
                    // console.log(result)
                    values.primary_partner = result.primary_partner
                    values.primary_partner_name = result.primary_partner_name
                    values.primary_partner_email = result.primary_partner_email
                    values.assigned_health = result.assigned_health
                    values.assigned_legal = result.assigned_legal
                    values.assigned_food = result.assigned_food
                    values.assigned_other = result.assigned_other
                })
                submitLog(values)
                submitForm(values)
                emailApplicant(values)
                emailProvider(values)
                setStep(s => 9)
            } else {
                next(values)
            }
        };

        return (
            <>
                <Formik
                    initialValues={{ ...formikData }}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={validateSchema[step]}
                >
                    {({ values, errors, touched, handleChange, handleSubmit, handleBlur }) => (
                        <Form>
                            {renderStep(step, values, errors, touched, handleChange, handleSubmit, next, back, signupSuccess)}
                            {step != 9 ?
                                <div className="level is-mobile mt-6" style={{ display: "flex" }}>
                                    <div className="level-left">
                                        {step == 1 ?
                                            <Link
                                                to="/"
                                                className="button is-secondary level-item"
                                            >
                                                Return to Home
                                            </Link>
                                            : null}
                                        {step > 1 ?
                                            <button
                                                className="button is-secondary level-item"
                                                onClick={back}
                                                type="button"
                                            >
                                                {blok.body[0].back}
                                            </button>
                                            : null
                                        }
                                    </div>
                                    <div className="level-right">
                                        {step == 2 && values.inLAcounty == 0 ?

                                            <button
                                                className="button is-primary level-item"
                                                type="submit"
                                            >
                                                {
                                                    values.inLAcounty == 0 ? "Check Address" :
                                                        values.inLAcounty == 2 ? blok.body[2].processing :
                                                            values.inLAcounty == 3 ? "Check Address" :
                                                                blok.body[2].next
                                                }
                                            </button>
                                            : null
                                        }
                                        {step == 1 || (step == 2 && values.inLAcounty != 0) || step > 2 ?
                                            <button
                                                className="button is-primary level-item"
                                                onClick={handleSubmit}
                                                type="submit"
                                            >
                                                {
                                                    step != 8 ? blok.body[0].next :
                                                        blok.body[0].submit
                                                }
                                            </button>
                                            : null
                                        }
                                    </div>

                                </div>
                                : <div className="level is-mobile mt-6" style={{ display: "flex" }}>
                                    <div className="level-left"></div>
                                    <div className="level-right">
                                        <Link
                                            to="/"
                                            className="button is-primary level-item"
                                        >
                                            Return to Home
                                        </Link>
                                    </div>
                                </div>
                            }
                            {/* <Debug /> */}
                        </Form>
                    )}
                </Formik>
            </>
        );
    };


    return (
        <div {...sbEditable(blok)}>
            <div className="container my-6">
                <div className="columns is-multiline is-centered">
                    <div className="column is-8">
                        <div className="card mx-3 mt-5">
                            <div className="card-content">
                                <MultiStep />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntakeForm
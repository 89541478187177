import React from 'react';
import { render } from "storyblok-rich-text-react-renderer"
import { ErrorMessage, Field } from "formik";

const Step07_Submit = props => {
    console.log("Submitted form")
    const { values, content, back, next, handleSubmit } = props

    return <div>
        <progress className="progress" value="90" max="100">
            90%
        </progress>

        <div className="content">
            <div className="notification is-warning is-light mt-3">
                <div className="content">
                    {render(content.submit_description)}
                </div>
            </div>
        </div>
    </div >
}

export default Step07_Submit;
import React from 'react';
import { ErrorMessage, Field } from "formik";
import { render } from "storyblok-rich-text-react-renderer"

const Step00_Start = props => {
    const { values, content, back, next, handleSubmit } = props

    return <div>
        <div className="content">
            <p className="is-size-3 label">
                {content.title}
            </p>
            {render(content.howthisworks)}
            <div className="notification">
                {render(content.privacy)}
                {render(content.consent)}
            </div>
        </div>
    </div >
}

export default Step00_Start;
import React from 'react';
import { ErrorMessage, Field } from "formik";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const phoneNumberMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,];

const Step05_ContactInfo = props => {
    const { values, content, back, next, document, errors, touched, handleBlur } = props

    const lang_list = content.language.map(language => (
        { value: language, label: language.charAt(0).toUpperCase() + language.slice(1) }
    )
    )
    const animatedComponents = makeAnimated();

    return <>

        <progress className="progress" value="60" max="100">
            60%
        </progress>
        <p className="is-size-3 label">
            {content.question}
        </p>
        <div className="field pb-4">
            <p className="label">{content.phone}</p>
            <Field
                name="phone"
                children={({ field, form }) => (
                    <MaskedInput
                        className="input column is-one-third"
                        {...field}
                        mask={phoneNumberMask}
                        id="phone"
                        placeholder={content.phone}
                        type="text"
                    />
                )}
            />
            <ErrorMessage
                className="tag is-warning is-normal has-text-weight-semibold"
                component="div"
                name="phone"
            />
        </div>
        <div className="field pb-4">
            <p className="label" htmlFor="first_name">
                {content.firstname}
            </p>
            <Field
                className="input"
                autoComplete="given-name"
                component="input"
                id="first_name"
                name="first_name"
                placeholder={content.firstname}
                type="text"
            />
            <ErrorMessage
                className="tag is-warning is-normal has-text-weight-semibold"
                component="div"
                name="first_name"
            />
        </div>
        <div className="field pb-4">
            <p className="label" htmlFor="last_name">
                {content.lastname}
            </p>
            <Field
                className="input"
                autoComplete="family-name"
                component="input"
                id="last_name"
                name="last_name"
                placeholder={content.lastname}
                type="text"
            />
            <ErrorMessage
                className="tag is-warning is-normal has-text-weight-semibold"
                component="div"
                name="last_name"
            />
        </div>
        <div className="field pb-4">
            <p className="label" htmlFor="email">
                {content.email}
            </p>
            <Field
                className="input"
                autoComplete="email"
                component="input"
                id="email"
                name="email"
                placeholder="Email"
                type="text"
            />
            <ErrorMessage
                className="tag is-warning is-normal has-text-weight-semibold"
                component="span"
                name="email"
            />
        </div>
        <div className="field pb-4">
            <p className="label" htmlFor="preferred_language">
                {content.preferred_language}
            </p>
            <Field
                name="preferred_language"
                children={({ field, form }) => (
                    <Select
                        // closeMenuOnSelect={false}
                        className="basic-single"
                        classNamePrefix="select"
                        // components={animatedComponents}
                        isSearchable={false}
                        options={lang_list}
                        onChange={(option) => {
                            // console.log(option)
                            // const list = option.map(val => val.value)
                            // console.log(list)
                            form.setFieldValue("preferred_language", [option.value]);
                        }}
                    />
                )}
            />
            <ErrorMessage
                className="tag is-warning is-normal has-text-weight-semibold"
                component="span"
                name="preferred_language"
            />
        </div>
        <div className="field pb-4">
            <p className="label" htmlFor="langugage_spoken">
                {content.language_spoken}
            </p>
            <Field
                name="langugage_spoken"
                children={({ field, form }) => (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={lang_list}
                        onChange={(option) => {
                            console.log(option)
                            const list = option.map(val => val.value)
                            // console.log(list)
                            form.setFieldValue("langugage_spoken", list);
                        }}
                    />
                )}
            />
            <ErrorMessage
                className="tag is-warning is-normal has-text-weight-semibold"
                component="span"
                name="langugage_spoken"
            />
        </div>
        <div className="field pb-4">
            <p className="label">
                {content.accommodation}
            </p>
            <div className="control" id="radioGroup_assets">
                <label className="radio mr-4">
                    <Field type="radio" name="accommodations" value="yes" className="mr-2" />
                    {content.Yes}
                </label>
                <label className="radio">
                    <Field type="radio" name="accommodations" value="no" className="mr-2" />
                    {content.No}
                </label>
                <ErrorMessage
                    className="tag is-warning is-normal has-text-weight-semibold"
                    component="div"
                    name="accommodations"
                />
                {values.accommodations === "yes" ?
                    <Field component="textarea" name="accommodation_request" className="textarea mt-3" />
                    : null
                }
            </div>
        </div>
    </>
}

export default Step05_ContactInfo;
export default {
    //Step01 Address Search
    geocoded_address: "",
    street_address: "",
    apt:"",
    city: "",
    zip: "",
    inLAcounty: 0,
    lat: null,
    long: null,
    spa_area: "",
    community: "",

    //Step02 Select Services
    submitted_requests: [],

    //Step03 Household
    client_an_immigrant: "",

    //Step04 Income Covid
    client_income_covid19: "",

    //Step05 Contact Info
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    langugage_spoken: [],
    preferred_language: [],
    accommodations: "",
    accommodation_request: "",

    //Step06 Outreach By
    referredbyprovider: "",
    outreached_by: "",
    outreached_other: "",

    //Processing
    primary_partner: null,
    primary_partner_name: "",
    
    primary_partner_email: null,
    assigned_health: [],
    assigned_legal: [],
    assigned_food: [],
    assigned_other: [],

    //Referrer Contact Info
    referred_by_name: "",
    referred_by_email: ""
}